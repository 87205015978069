@mixin center($align: center, $justify: center) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

$shadow-A: 5px 5px 9px #5a5a5a, -5px -5px 9px #ffffff;

$defaultRotateX: 0;
$defaultRotateY: 0;
$defaultRotateZ: 0;
$power: 20;

@mixin grid-A {
  height: 40vh;

  @media (max-width: 1400px) {
    height: 55vh;
  }

  @media (max-width: 1200px) {
    height: 70vh;
  }

  @media (max-width: 992px) {
    height: 85vh;
  }

  @media (max-width: 768px) {
    height: 100vh;
  }
}

@mixin my-table {
  .my-table {
    max-width: 2560px;
    min-height: 22vh;

    & h1 {
      margin: 4rem;
      font-family: 'Sevillana', cursive;
    }

    .td {
      vertical-align: middle;
      height: 15vh;
    }

    .td-article {
      width: 7%;
    }

    .td-img {
      width: 15%;

      &--pic {
        height: 10rem;
        object-fit: cover;
        cursor: pointer;
        transition: 0.5s;
        &:active {
          transform: scale3d(2, 2, 2);
        }
      }
    }
  }

  .td-title {
    width: auto;
  }

  .td-price {
    width: 10%;
  }

  .td-delete {
    & Button {
      margin: 1rem 0;
    }

    width: 10%;
  }
}


.err {
  margin: 1rem;
  font-size: 2rem;
  color: red;
}
