@import './../../mixins.scss';

$color-background: linear-gradient(360deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 100%);
$color-footer: #B9B9B9;

.footer{
  margin-top: 2rem;
  @include center;
  flex-direction: column;
  background: $color-background;
  font-family: 'Raleway', sans-serif;

  &-title{
    margin-top: 2rem;
    color: #FEFEFE;
    text-transform: uppercase;
  }

  & p{
    text-align: center;
    & .item-big{
      display: none;
    }
    @media (min-width: 768px){
      &{
        font-size: 0.87rem;
      }
      & .item-big{
        display: inline;
      }
      &{
        min-width: 680px;
      }
    }

    width: 19rem;
    margin: 0.6rem;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: $color-footer;
  }
}

.contacts{
  @media (min-width: 768px){
    display: inline-flex;
  }

  span{
    @include center;
    font-weight: 400;
    font-size: 0.87rem;
    line-height: 1.5rem;
    color: $color-footer;
    margin: 0.3rem;
    & a{
      font-size: 1rem;
      color: $color-footer;
      font-weight: 400;
    }
  }
  margin-bottom: 3rem;
}