$gradient-18: linear-gradient(180deg, rgba(0, 0, 0, 1) 18%, rgba(0, 0, 0, 0) 100%);
$gradient-35: linear-gradient(180deg, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 0) 100%);

.navbar-my-bg {
  background: $gradient-18;
  height: 7rem;
}

.navbar {
  z-index: 10;

  .navbar .navbar-brand{
    margin-left: 4vw;
      font-size: 2rem;
    font-weight: 100;
  }

  & .navbar-collapse {
    padding: 1rem;
  }

  & .nav-link {
    color: #fff;
    background: $gradient-18;
    height: 4rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;

    &:hover {
      background: $gradient-35;
    }
  }

  & .navbar-toggler {
    margin-right: 1rem;
    background-color: #fff;
  }

  & .dropdown-toggle {
    background: $gradient-18;
    height: 4rem;
  }

  & .dropdown-menu {
    background-color: transparent;
    border: none;

    & li {
      background: $gradient-18;

      & .dropdown-item {
        font-family: 'Raleway', sans-serif;
        font-weight: 400;
        color: #fff;
        height: 4rem;
        cursor: pointer;
        &:hover {
          background: $gradient-18;
          color: #fff;
        }
      }
    }
  }


  & .navbar-brand {
    font-family: 'Sevillana', cursive;
    font-style: italic;
    font-weight: bold;
    font-size: 1.4rem;
    color: #fff;
  }

  & a {
    font-family: 'Raleway', sans-serif;
    font-weight: 200;
    font-size: 1rem;
    cursor: pointer;
  }

  .search {
    margin-right: 1rem;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    & .input-search {
      background: $gradient-18;
      color: #fff;

      &::placeholder {
        color: #fff;
      }

      &::-moz-placeholder {
        color: #fff;
      }
    }

    & .btn-search {
      background: $gradient-18;
      color: #fff;
    }
  }

  & .navbar-my-icon {
    padding: 0 1rem;
    height: 4rem;
    display: flex;
    align-items: center;
    background: $gradient-18;

    & .counter-icon {
      font-family: 'Raleway', sans-serif;
      font-weight: 200;
      font-size: 1rem;
      color: #fff;
    }
  }
}
