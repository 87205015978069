@import  './../../mixins.scss';

.my-modal {
  position: fixed;
  @include center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  z-index: 20;

  & .modal-window {
    position: relative;
    width: 30rem;
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.5);
    background-color: #6c757d;
    font-family: 'HelveticaNeueCyr', sans-serif; // Condensed Black
    font-weight: 500;
    color: #fff;
    cursor: default;
    z-index: 20;

    & .modal-header {
      @include center($justify: space-between);
      font-size: 1rem;
      border-radius: 3px;
      height: 1.5rem;
      padding: 1rem;
      background-color: #5a6168;
      font-weight: 700;
    }

    & .modal-body {
      position: relative;
      display: flex;
      flex-direction: column;
      font-size: 0.7rem;
      line-height: 1.5rem;
      font-weight: 700;

      & .modal-content{
        width: calc(100% - 4rem);
        margin: 1rem auto;
      }

      & .modal-button{
        display: flex;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.modal-await{
  position: fixed;
  @include center;
  z-index: 20;

  & .modal-window {
    color: #fff;
    z-index: 20;
  }
}
