@import './../../mixins.scss';

.main {
  &--trek {
    position: relative;
    font-family: 'Sevillana', cursive;
    font-style: italic;
    font-weight: 200;
    font-size: 2rem;
    color: #000;
    margin: 4rem 0 5rem;
    white-space: nowrap;
    text-shadow: $shadow-A;
    cursor: pointer;
  }

  &--trek::before {
    content: '';
    position: absolute;
    border-bottom: 1.2px solid #000;
    margin-top: 5rem;
    left: calc(50% - 2.8rem);
    width: 6rem;
  }

  &--case {
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;

    @media (max-width: 1400px) {
      grid-template: 1fr / 1fr 1fr 1fr 1fr;
    }

    @media (max-width: 1200px) {
      grid-template: 1fr / 1fr 1fr 1fr;
    }

    @media (max-width: 992px) {
      grid-template: 1fr / 1fr 1fr;
    }

    @media (max-width: 768px) {
      grid-template: 1fr / 1fr 1fr;
    }

    @media (max-width: 576px) {
      grid-template: 1fr / 1fr;
    }

    justify-items: center;
    gap: 1.5rem;
  }
}

.my-spinner{
  position: relative;
  top: 2rem;
}