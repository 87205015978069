@import './../../mixins.scss';

.stand {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
  height: 42rem;
  //border: #D4AF37 solid 1px;
  border-radius: 5px;
  background: #e0e0e0;
  box-shadow: $shadow-A;
  transition: 0.5s;
  &:hover{
    transform: scale3d(1.02,1.02,1.02);
  }
  &--img-top {
    display: block;
    object-fit: cover;
    border-radius: 5px;
    width: 100%;
    height: 50%;
    cursor: pointer;
  }

  &--body {
    @include center($align: space-between, $justify: space-between);
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    flex-direction: column;
    color: #000;
  }

  &--title {
    margin: 0.5rem;
    font-size: 1rem;
    white-space: nowrap;
    text-align: left;
  }

  &--stance {
    font-size: 1rem;
    text-align: left;
    @include center($justify: space-between);
    flex-wrap: wrap;

    & small {
      margin-right: 0.5rem;
    }
  }

  &--star {
    width: 100%;
    @include center($justify: flex-end);
  }

  &--price {
    color: green;
    font-weight: bold;
    font-size: 1.4rem;
  }

  &--text-muted {
    @include center($justify: flex-start)
  }

  &--button {
    width: 100%;
    @include center($justify: space-between);
    flex-wrap: wrap;
  }

  &--button-add {
    margin-bottom: 0.5rem;
  }
}