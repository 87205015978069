@import './../../mixins.scss';

.test {
  button {
    margin: 3rem 0.5rem;
    padding: 0.2rem;
  }

  li {
    list-style: none;
  }
}