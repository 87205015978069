.order-wrap {
  margin: 7rem auto 0;
  min-height: calc(100vh - 26rem);
  max-width: 50rem;

  & .my-table {
    margin: 0 auto 0;

    & .table--trek {
      text-align: left;
      margin: 0.5rem;
    }

    Table {
      thead {

      }

      tbody {
        tr {
          & .td {
            height: 3rem;
          }

          & .td-article {

          }

          & .td-title {

          }

          & .td-price {

          }

          & .td-count {
            text-align: center;
            white-space: nowrap;

            & span {
              padding: 0.5rem;
            }

            .btn-order {
              width: 2rem;
            }
          }

          & .td-delete {
            font-size: 2rem;
            color: red;
            cursor: pointer;
          }
        }
      }

      tfoot {
        & .order-total {
          text-align: left;
        }
      }
    }
  }
}

Form {
  .hr {
    width: 100%;
  }

  .pattern-format {
    border: solid 1px #dee2e6;
    border-radius: 5px;
    height: 2.3rem;
    background: #e8f0fe;

    &:focus {
      outline: none;
      border: 1px solid #7bb8ff;
      border-radius: 5px;
      box-shadow: 0 0 5px #badaff, 0 0 0 3.5px #badaff;
    }

    &--inherit {
      width: inherit;
    }

    &--width {
      width: 100%;
    }
  }
}

.form-errors {
  background: #dc3545;
  padding: 0.3rem;
  border-radius: 5px;
  color: #fff;
}

.form-btn {
  margin-top: 1rem;
}

.none{
  display: none;
}

.center{
  text-align: center;
}


