@import './../../mixins.scss';

$bg-color-A: rgb(156, 58, 43);
$bg-color-A-hover: rgba(156, 58, 43, .7);
$bg-color-b: #1e574f;
$bg-color-b-hover: #246a61;

.btn-a {
  color: #fff;
  background-color: $bg-color-A;
  border: none;
  padding: 0.5rem;
  font-family: 'HelveticaNeueCyr', sans-serif; // Condensed Black
  font-weight: 500;
  font-size: 0.7rem;
  border-radius: 3px;
  width: 4rem;
  cursor: pointer;
  box-shadow: 2px 3px 3px $bg-color-A;

  &:hover {
    background-color: $bg-color-A-hover;
  }

  &:active {
    box-shadow: none;
  }
}

.btn-c {
  position: relative;
  margin: 0;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  border: none;
  &::before,
  &::after {
    content: '';
    position: absolute;
    right: -0.2rem;
    width: 1.3rem;
    border-bottom: 1.5px solid #fff;
  }

  &::before {
    rotate: 45deg;
  }

  &::after {
    rotate: -45deg;
  }
}

.btn-d {
  color: #fff;
  background-color: $bg-color-b;
  border: none;
  padding: 0.5rem;
  font-family: 'HelveticaNeueCyr', sans-serif; // Condensed Black
  font-weight: 500;
  font-size: 0.7rem;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
  box-shadow: 2px 3px 3px $bg-color-b;

  &:hover {
    background-color: $bg-color-b-hover;
  }

  &:active {
    box-shadow: none;
  }
}

.btn-width-50{
  width: 50%;
}

.btn-nav-link{
  text-decoration: none;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

