@import './../../mixins.scss';

.wrap {
  text-align: center;

  &--excuse {
    font-family: 'Raleway', sans-serif;

    & a {
      color: #282c34;
      font-family: inherit;
      font-size: 1.1rem;
    }
  }

  & .trek {
    margin: 4rem;
    font-family: 'Sevillana', cursive;

    & a {
      color: #282c34;
      text-decoration: none;

      &:hover {
        color: #7d879b;
      }
    }
  }

  .item-zer {
    margin: 10rem 0;

    &--wr-img {
      width: 100%;

      & img {
        width: inherit;
        object-fit: cover;
      }
    }
  }

  & .item-one {
    height: auto;
    @include center($justify: space-between);
    flex-direction: column;
    font-family: 'Raleway', sans-serif;
    line-height: 1.5rem;

    &--wr-img {
      width: 70vw;

      & img {
        object-fit: cover;
        width: 70vw;
      }
    }

    &--wr-co {
      width: 90%;
      padding: 2rem;
      @include center;
      flex-direction: column;

      &--svg {
        margin: 4rem;
      }

      & p {
        max-width: 100vw;
      }
    }

    @media (min-width: 1200px) {
      flex-direction: row;
      &--wr-img {
        width: 40vw;

        & img {
          object-fit: cover;
          width: 40vw;
        }
      }

      &--wr-co {
        width: 50%;

        & p {
          max-width: 35rem;
        }
      }
    }
  }
}