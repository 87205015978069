@import './../../mixins.scss';

.bigStand {
  max-width: 1800px;
  @include center($justify: space-between);
  flex-direction: column;
  background: transparent;

  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &--img-top {
    object-fit: cover;
    height: 50vh;
    width: 100%;
    box-shadow: $shadow-A;
    @media (min-width: 768px) {
      width: 55%;
    }
  }

  &--body {
    @include center($align: space-between, $justify: space-between);
    height: inherit;
    width: 90vw;
    flex-direction: column;
    padding-right: 1rem;
    padding-left: 1rem;

    @media (min-width: 992px) {
      padding-right: 3rem;
      padding-left: 3rem;
    }

    font-family: 'Sevillana', cursive;
    color: #000;
  }

  &--title {
    margin: 2rem;
    font-size: 2rem;

    @media (min-width: 768px) {
      font-size: 3rem;
    }

    font-family: 'Sevillana', cursive;
    text-shadow: $shadow-A;
    text-align: left;
  }

  &--stance-title {
    font-family: 'Raleway', sans-serif;
    @include center;
    flex-wrap: wrap;
  }

  &--stance-elect {
    text-align: right;
    width: 100%;
    white-space: nowrap;
  }

  &--stance {
    font-family: 'Raleway', sans-serif;
    text-align: left;
    @include center($justify: flex-start);


    @media (min-width: 768px) {
      flex-direction: row;
      font-size: 2rem;
    }
    flex-direction: column;
    font-size: 1rem;

    & small {
      margin-right: 2rem;
    }
  }

  &--price {
    font-family: 'Raleway', sans-serif;
    color: green;
    font-weight: bold;
    font-size: 1.4rem;
  }

  &--text-muted {
    @include center($justify: flex-start)
  }

  &--button {
    @include center($justify: flex-end);

    &--btn {
      margin: 1rem;
    }
  }
}